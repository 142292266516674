
import ToolTip from '@/components/ToolTip.vue'
import { translationsStore } from '@/store'
import { ILanguage } from '@/__types__/_pro/translations/translations'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LanguageSelect',
  computed: {
    ...mapState(translationsStore, ['selectedLanguage', 'activeLanguages']),
    flagSrc(): string {
      return this.selectedLanguage.countryCode.toLowerCase()
    },
  },
  methods: {
    onLangSelectClick(lang: ILanguage) {
      const query = { ...this.$route.query }

      // Add DK lang as well - will get removed in router
      query.lang = lang.locale

      const queryArray = Object.entries(query).map(([key, value]) => {
        return `${key}=${value}`
      })

      // Reload site with new query
      window.location.href = `${
        window.location.origin + window.location.pathname
      }?${queryArray.join('&')}`
    },
  },
  components: { ToolTip },
})
