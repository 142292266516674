import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c4424f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MenuMobile" }
const _hoisted_2 = { class: "Label" }
const _hoisted_3 = { class: "FilterButtonContent" }
const _hoisted_4 = {
  key: 0,
  class: "FilterState"
}
const _hoisted_5 = { class: "Label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_MenuToggle = _resolveComponent("MenuToggle")!
  const _component_FilterButton = _resolveComponent("FilterButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MenuToggle, {
      shouldMinimize: false,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close('filter')))
    }, {
      default: _withCtx(({ menuState }) => [
        _createVNode(_component_InlineSVG, {
          class: _normalizeClass(["Icon", {
            IsHidden: menuState.isHidden,
          }]),
          src: require('@/assets/svg/menu-hide.svg')
        }, null, 8, ["class", "src"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.mixWB('MENU')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_InlineSVG, {
      class: "Logo",
      src: require('@/assets/logo/milva-logo-white.svg')
    }, null, 8, ["src"]),
    (_ctx.$route.meta.url)
      ? (_openBlock(), _createBlock(_component_FilterButton, {
          key: 0,
          class: _normalizeClass({'IsDisabled': _ctx.filterIsDisabled}),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close('menu')))
        }, {
          default: _withCtx(({
          isOpen
        }) => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.filterIsActive)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "1"))
                : _createCommentVNode("", true),
              isOpen
                ? (_openBlock(), _createBlock(_component_InlineSVG, {
                    key: 1,
                    class: "Icon",
                    src: require('@/assets/svg/close.svg')
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_InlineSVG, {
                    key: 2,
                    class: "Icon",
                    src: require('@/assets/svg/filter.svg')
                  }, null, 8, ["src"])),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mixWB('FILTER')), 1)
            ])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}