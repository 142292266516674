import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8004493e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "Info" }
const _hoisted_4 = { class: "Address" }
const _hoisted_5 = { class: "Caption" }
const _hoisted_6 = {
  key: 2,
  class: "Selected"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["FilterProject", { 'IsMarked': !_ctx.isDisabled && _ctx.isMarked}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDisabled ? null : _ctx.$emit('clicked', _ctx.project.id)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.frontImage)
          ? (_openBlock(), _createBlock(_component_Image, {
              key: 0,
              image: _ctx.frontImage,
              dimensions: {width: 40, height: 40},
              transformation: 'square_40'
            }, null, 8, ["image"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.projectAddress), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.customCaseNumber || _ctx.caseNumber) + " - " + _toDisplayString(_ctx.buildingText), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["Status", _ctx.status.className])
          }, _toDisplayString(_ctx.status.text), 3)
        ]),
        (_ctx.isMarked)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.mixWB('SELECTED')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}