import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock("button", {
    class: "MenuToggle",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeMenuState && _ctx.changeMenuState(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {
      isMinimized: _ctx.menu.isMinimized,
      menuState: _ctx.menu
    }, () => [
      _createVNode(_component_InlineSVG, {
        class: _normalizeClass(["Burger ButtonIcon", {
          IsMinimized: _ctx.menu.isMinimized,
        }]),
        src: require('@/assets/svg/menu-hide.svg')
      }, null, 8, ["class", "src"])
    ], true)
  ]))
}