import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-294048a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "DevMenu"
}
const _hoisted_2 = { class: "Button" }
const _hoisted_3 = {
  key: 0,
  class: "SubMenu"
}
const _hoisted_4 = { class: "Button" }
const _hoisted_5 = {
  key: 0,
  class: "SubMenu CustomScrollbar"
}
const _hoisted_6 = { class: "Button" }
const _hoisted_7 = {
  key: 0,
  class: "SubMenu CustomScrollbar"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "Button" }
const _hoisted_11 = {
  key: 0,
  class: "SubMenu CustomScrollbar"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isDevEnv)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToggleSubNav()))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('DEVELOPER_MENU')), 1),
            _createVNode(_component_InlineSVG, {
              class: "Icon",
              src: require('@/assets/svg/angle-right.svg')
            }, null, 8, ["src"])
          ])
        ]),
        (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
          (_ctx.states.subNavIsOpen)
            ? (_openBlock(), _createElementBlock("nav", _hoisted_3, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createElementVNode("button", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickListToggle('pdfs')))
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('PDFS')), 1),
                        _createVNode(_component_InlineSVG, {
                          class: "Icon",
                          src: require('@/assets/svg/angle-right.svg')
                        }, null, 8, ["src"])
                      ])
                    ]),
                    (_ctx.states.pdfListIsOpen)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pdfs, (pdf) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: pdf.reference
                            }, [
                              _createVNode(_component_router_link, {
                                class: "Button",
                                to: { name: pdf.component, query: { reference: pdf.reference }}
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(pdf.title), 1),
                                  _createVNode(_component_InlineSVG, {
                                    class: "Icon",
                                    src: require('@/assets/svg/angle-right.svg')
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("li", null, [
                    (_ctx.isEnterpriseProject)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("button", {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickListToggle('backend')))
                          }, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SHOW_IN_BACKEND')), 1),
                              _createVNode(_component_InlineSVG, {
                                class: "Icon",
                                src: require('@/assets/svg/angle-right.svg')
                              }, null, 8, ["src"])
                            ])
                          ]),
                          (_ctx.states.backendListIsOpen)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: project.id
                                  }, [
                                    _createElementVNode("a", {
                                      class: "Button",
                                      href: _ctx.backendUrl(project.id),
                                      target: "_blank"
                                    }, [
                                      _createElementVNode("span", null, _toDisplayString(project.getCaseNumber()), 1),
                                      _createVNode(_component_InlineSVG, {
                                        class: "Icon",
                                        src: require('@/assets/svg/angle-right.svg')
                                      }, null, 8, ["src"])
                                    ], 8, _hoisted_8)
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          class: "Button",
                          href: _ctx.backendUrl(_ctx.projectID),
                          target: "_blank"
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SHOW_IN_BACKEND')), 1),
                          _createVNode(_component_InlineSVG, {
                            class: "Icon",
                            src: require('@/assets/svg/angle-right.svg')
                          }, null, 8, ["src"])
                        ], 8, _hoisted_9))
                  ]),
                  _createElementVNode("li", null, [
                    (_ctx.isEnterpriseProject)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("button", {
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClickListToggle('storage')))
                          }, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SHOW_IN_STORAGE')), 1),
                              _createVNode(_component_InlineSVG, {
                                class: "Icon",
                                src: require('@/assets/svg/angle-right.svg')
                              }, null, 8, ["src"])
                            ])
                          ]),
                          (_ctx.states.storageListIsOpen)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: project.id
                                  }, [
                                    _createElementVNode("a", {
                                      class: "Button",
                                      href: _ctx.storageUrl(project.id),
                                      target: "_blank"
                                    }, [
                                      _createElementVNode("span", null, _toDisplayString(project.getCaseNumber()), 1),
                                      _createVNode(_component_InlineSVG, {
                                        class: "Icon",
                                        src: require('@/assets/svg/angle-right.svg')
                                      }, null, 8, ["src"])
                                    ], 8, _hoisted_12)
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          class: "Button",
                          href: _ctx.storageUrl(_ctx.projectID),
                          target: "_blank"
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SHOW_IN_STORAGE')), 1),
                          _createVNode(_component_InlineSVG, {
                            class: "Icon",
                            src: require('@/assets/svg/angle-right.svg')
                          }, null, 8, ["src"])
                        ], 8, _hoisted_13))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      ]))
    : _createCommentVNode("", true)
}