
import Image from '@/components/Image.vue'
import { ProProject } from '@/globals/javascript/models/ProProject/ProProject'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { countriesStore, currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'FilterProject',
  props: {
    project: {
      type: Object as PropType<ProProject>,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    isMarked: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { customCaseNumber, caseNumber, frontImage } = this.project.data

    return {
      frontImage,
      customCaseNumber,
      caseNumber,
      status: this.project.status,
      isDisabled: this.disable,
      tag: this.disable ? 'div' : 'button',
    }
  },
  computed: {
    ...mapState(currentStore, {
      account: 'account',
      buildingText: ({ units }) => {
        const activeBuildingCount = units.active.buildings.length

        if (activeBuildingCount === 1) {
          return mixWB('1_BUILDING')
        }

        return mixWB('X_BUILDINGS', [activeBuildingCount.toString()])
      },
    }),
    projectAddress(): string {
      if (!this.project) {
        return ''
      }

      if (!this.account) {
        return ''
      }

      const { baseCountry } = this.account
      const { countryCode: projectCountry } = this.project.data

      const { address, city, postalCode } = this.project.data.address
      const addressLine = `${address}, ${postalCode} ${city}`

      // Return default addresse setup if projectCountry is falsy or same as baseCountry
      if (!projectCountry || baseCountry === projectCountry) {
        return addressLine
      }

      const { countries } = countriesStore()
      const country = countries.find((c) => c.countryCode === projectCountry)

      // If we have no collection of selected project country and return default
      if (!country) {
        return addressLine
      }

      // Add country to default address line
      return `${addressLine}, ${mixWB(country.translation)}`
    },
  },
  components: { Image },
})
