
import FilterButton from '@/components/filter/FilterButton.vue'
import MenuToggle from '@/components/menu/MenuToggle.vue'
import { appStore, filterStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuMobile',
  computed: {
    ...mapState(appStore, ['menu']),
    ...mapState(filterStore, {
      filterIsActive: 'isActive',
      filterIsDisabled: 'disabled',
      filterIsOpen: 'isOpen',
    }),
  },
  methods: {
    ...mapActions(filterStore, ['setIsOpen']),
    ...mapActions(appStore, ['setMenuHiddenState']),
    close(overlay: 'filter' | 'menu') {
      if (overlay === 'filter' && this.filterIsOpen) {
        this.setIsOpen(false)
        return
      }

      if (overlay === 'menu' && !this.menu.isHidden) {
        this.setMenuHiddenState(true)
        return
      }
    },
  },
  components: { MenuToggle, FilterButton },
})
