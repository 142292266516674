import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54a45c5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "LanguageSelect"
}
const _hoisted_2 = { class: "LangOption ActiveCountry" }
const _hoisted_3 = { class: "LangIcon" }
const _hoisted_4 = { class: "LangTitle" }
const _hoisted_5 = { class: "LangOptionsWrap" }
const _hoisted_6 = { class: "LangOptionsTitle" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "LangIcon" }
const _hoisted_9 = { class: "LangTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_ToolTip = _resolveComponent("ToolTip")!

  return (_ctx.selectedLanguage && _ctx.selectedLanguage.countryCode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ToolTip, {
          placement: "below",
          isOnlyClick: true
        }, _createSlots({
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_InlineSVG, {
                  src: require(`@/assets/flags/1x1/${_ctx.flagSrc}.svg`)
                }, null, 8, ["src"])
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.selectedLanguage.countryCode), 1)
            ])
          ]),
          _: 2
        }, [
          (_ctx.activeLanguages.length)
            ? {
                name: "message",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.mixWB('SELECT_LANGUAGE')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeLanguages, (lang) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: lang.countryCode
                      }, [
                        (lang.countryCode !== _ctx.selectedLanguage.countryCode)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "LangOption",
                              onClick: ($event: any) => (_ctx.onLangSelectClick(lang))
                            }, [
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_InlineSVG, {
                                  src: require(`@/assets/flags/1x1/${lang.countryCode.toLowerCase()}.svg`)
                                }, null, 8, ["src"])
                              ]),
                              _createElementVNode("span", _hoisted_9, _toDisplayString(lang.countryCode), 1)
                            ], 8, _hoisted_7))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ])
                ])
              }
            : undefined
        ]), 1024)
      ]))
    : _createCommentVNode("", true)
}