
import Accordion from '@/components/Accordion.vue'
import FilterProject from '@/components/filter/FilterProject.vue'
import Image from '@/components/Image.vue'
import { currentStore, filterStore, proProjectsStore } from '@/store'
import { IFilterQuery } from '@/__types__/filter/filter'
import { isEqual } from 'lodash-es'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Filter',
  data() {
    return {
      params: {
        projectId: undefined,
        unit: 'all',
      } as IFilterQuery,
    }
  },
  computed: {
    ...mapState(filterStore, {
      filterPrestineParams: 'prestineParams',
      filterQuery: 'query',
      filterValues: 'values',
      filterIsOpen: 'isOpen',
    }),
    ...mapState(proProjectsStore, ['projects']),
    ...mapState(currentStore, { currentProject: 'project' }),
    ...mapState(currentStore, [
      'isEnterpriseProject',
      'isSingleProject',
      'units',
    ]),
    paramsUnchanged(): boolean {
      return isEqual(this.params, this.filterValues)
    },
  },
  watch: {
    filterValues: {
      // Keeping local parameters in sync with store value change
      handler(val) {
        this.params = { ...val }
      },
      deep: true,
      immediate: true,
    },
    filterIsOpen(val) {
      // Reset values when the filter is closed
      if (!val) {
        setTimeout(() => {
          this.params = { ...this.filterValues }
        }, 200)
      }
    },
  },
  methods: {
    ...mapActions(filterStore, ['setValues', 'setIsOpen', 'setForcedActive']),
    applyFilter() {
      if (this.filterValues.projectId !== this.params.projectId) {
        this.resetParams()
      }

      this.setValues(this.$route.query, this.params)
      this.setIsOpen(false)

      const goToURL = {
        name: this.$route.name ?? 'ProjectRoot',
        params: {
          projectID: this.params.projectId,
        },
        query: this.filterQuery,
      }

      this.$router.push(goToURL)
    },
    resetParams() {
      this.params = {
        ...this.params,
        ...this.filterPrestineParams,
      }
    },
    changeProjectID(projectID: string) {
      this.params.projectId = projectID
    },
  },
  components: { FilterProject, Accordion, Image },
  created() {
    // Apply query the URL have initiated with
    this.setValues(this.$route.query)

    if (this.isEnterpriseProject) {
      this.setForcedActive(true)
    }
  },
})
