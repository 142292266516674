
import LanguageSelect from '@/components/LanguageSelect.vue'
import DevMenu from '@/components/menu/DevMenu.vue'
import MenuToggle from '@/components/menu/MenuToggle.vue'
import ProjectDetails from '@/components/menu/ProjectDetails.vue'
import routeObjects from '@/router/routeObjects'
import { appStore, currentStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CookieConsent: any
  }
}

const isDisplaySmall = '(max-width: 650px)'
const isDisplayMedium = '(min-width: 651px) and (max-width: 1023px)'
const isStaticMode = '(min-width: 1024px)'
const smallMediaQueryList = window.matchMedia(isDisplaySmall)
const mediumMediaQueryList = window.matchMedia(isDisplayMedium)
const staticMediaQueryList = window.matchMedia(isStaticMode)

const useEventListener = !!mediumMediaQueryList.addEventListener

export default defineComponent({
  name: 'Menu',
  data() {
    const menuItems = [
      'Overview',
      'Fractions',
      'RemediationTasks',
      'SamplesAndAssesments',
      'RegistredMaterials',
      'BuildingsAndFloorPlans',
      'Resale',
    ]

    const secondaryMenuItems = ['Documents', 'Paradigm']

    const routes = menuItems.map((x) => routeObjects.find((r) => r.name === x))
    const secondaryRoutes = secondaryMenuItems.map((x) =>
      routeObjects.find((r) => r.name === x)
    )
    const commentRoute = routeObjects.find((r) => r.name === 'Comments')

    return {
      routes,
      secondaryRoutes,
      commentRoute,
    }
  },
  computed: {
    ...mapState(appStore, ['packageVersion', 'isResale', 'menu']),
    ...mapState(currentStore, {
      projectHasComments: (store) => !!store.project?.data.reportNotes ?? '',
    }),
    versionText(): string {
      return `${this.mixWB('VERSION_X', [this.packageVersion])} @ ${
        process.env.NODE_ENV
      }`
    },
  },
  watch: {
    $route: {
      handler() {
        const isOverlay = this.menu.mode === 'overlay'

        if (isOverlay && smallMediaQueryList.matches) {
          this.setMenuHiddenState(true)
        } else if (isOverlay) {
          this.setMenuMinimizedState(true)
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(appStore, [
      'setShownDialog',
      'setMenuMinimizedState',
      'setMenuMode',
      'setMenuHiddenState',
      'setDeviceType',
    ]),
    toggleMenuMode({ matches }: { matches: boolean }) {
      const mode = matches ? 'static' : 'overlay'
      this.setMenuMode(mode as 'static' | 'overlay')
    },
    toggleMinimizedState({ matches }: { matches: boolean }) {
      this.setMenuMinimizedState(matches)
    },
    toggleHiddenState({ matches }: { matches: boolean }) {
      this.setMenuHiddenState(matches)
    },
    toggleDeviceType({ matches }: { matches: boolean }) {
      const type = matches ? 'mobile' : 'above-mobile'
      this.setDeviceType(type as 'mobile' | 'above-mobile')
    },
    renewCookieConsent() {
      if (window.location.host.indexOf('localhost') === 0) {
        console.warn('CookieBot banner is disabled on localhost')
      } else {
        window.CookieConsent.renew()
      }
    },
  },
  components: { ProjectDetails, DevMenu, MenuToggle, LanguageSelect },
  created() {
    this.toggleMenuMode(staticMediaQueryList)
    this.toggleMinimizedState(mediumMediaQueryList)
    this.toggleHiddenState(smallMediaQueryList)
    this.toggleDeviceType(smallMediaQueryList)

    if (useEventListener) {
      staticMediaQueryList.addEventListener('change', this.toggleMenuMode)
      mediumMediaQueryList.addEventListener('change', this.toggleMinimizedState)
      smallMediaQueryList.addEventListener('change', this.toggleHiddenState)
      smallMediaQueryList.addEventListener('change', this.toggleDeviceType)
    } else {
      staticMediaQueryList.addListener(this.toggleMenuMode)
      mediumMediaQueryList.addListener(this.toggleMinimizedState)
      smallMediaQueryList.addListener(this.toggleHiddenState)
      smallMediaQueryList.addListener(this.toggleDeviceType)
    }
  },
  beforeUnmount() {
    if (useEventListener) {
      staticMediaQueryList.removeEventListener('change', this.toggleMenuMode)
      mediumMediaQueryList.removeEventListener(
        'change',
        this.toggleMinimizedState
      )
      smallMediaQueryList.removeEventListener('change', this.toggleHiddenState)
      smallMediaQueryList.removeEventListener('change', this.toggleDeviceType)
    } else {
      staticMediaQueryList.removeListener(this.toggleMenuMode)
      mediumMediaQueryList.removeListener(this.toggleMinimizedState)
      smallMediaQueryList.removeListener(this.toggleHiddenState)
      smallMediaQueryList.removeListener(this.toggleDeviceType)
    }
  },
})
