
import { ENV } from '@/globals/javascript/_utils/env'
import { HOST } from '@/globals/javascript/_utils/host'
import { currentStore, pdfStore, proProjectsStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DevMenu',
  data() {
    return {
      linksVisible: false,
      states: {
        backendListIsOpen: false,
        pdfListIsOpen: false,
        storageListIsOpen: false,
        subNavIsOpen: false,
      },
    }
  },
  computed: {
    ...mapState(currentStore, ['isEnterpriseProject', 'project']),
    ...mapState(proProjectsStore, ['projects']),
    ...mapState(pdfStore, {
      pdfs: ({ configs }) => Object.values(configs.all),
    }),
    isDevEnv() {
      return ENV.isDevelopment
    },
    projectID() {
      const id = this.$route.params.projectID

      if (Array.isArray(id)) {
        return id[0]
      }

      return id
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        this.resetStates()
      },
    },
  },
  methods: {
    resetStates() {
      this.states.backendListIsOpen = false
      this.states.pdfListIsOpen = false
      this.states.storageListIsOpen = false
      this.states.subNavIsOpen = false
    },
    toggleLinkBox(isActive: null | boolean = null) {
      this.linksVisible = isActive === null ? !this.linksVisible : isActive
    },
    onClickListToggle(listname: string, isActive: null | boolean = null) {
      if (listname === 'pdfs') {
        this.states.pdfListIsOpen =
          isActive === null ? !this.states.pdfListIsOpen : isActive
      }

      if (listname === 'backend') {
        this.states.backendListIsOpen =
          isActive === null ? !this.states.backendListIsOpen : isActive
      }

      if (listname === 'storage') {
        this.states.storageListIsOpen =
          isActive === null ? !this.states.storageListIsOpen : isActive
      }
    },
    onToggleSubNav(isActive: null | boolean = null) {
      this.states.subNavIsOpen =
        isActive === null ? !this.states.subNavIsOpen : isActive
    },
    backendUrl(projectID: string) {
      if (!projectID) {
        return ''
      }

      return `${HOST.firestore}/~2FScreenings~2F${projectID}`
    },
    storageUrl(projectID: string) {
      if (!projectID || !this.project) {
        return ''
      }

      const { screeningYear, screeningMonth, accountID } = this.project.data

      const base = `${HOST.firestorage}/~2FAccountScreenings`

      const accountFolder = `~2F${accountID}`
      const dateFolder = `~2F${screeningYear}-${screeningMonth}`
      const projectFolder = `~2F${projectID}`

      return base + accountFolder + dateFolder + projectFolder
    },
  },
})
