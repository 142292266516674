
import { appStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuToggle',
  props: {
    shouldMinimize: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(appStore, ['menu']),
  },
  methods: {
    ...mapActions(appStore, ['setMenuMinimizedState', 'setMenuHiddenState']),
    changeMenuState() {
      this.shouldMinimize
        ? this.setMenuMinimizedState()
        : this.setMenuHiddenState()
    },
  },
})
