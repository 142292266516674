
import FilterButton from '@/components/filter/FilterButton.vue'
import { currentStore, proProjectsStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProjectDetails',
  computed: {
    ...mapState(currentStore, ['project', 'isSingleProject', 'projectAddress']),
    ...mapState(proProjectsStore, ['projects']),
    numberOfProjectsText(): string {
      const count = this.projects.length

      if (count === 1) {
        return this.mixWB('1_PROJECT')
      }

      return this.mixWB('X_PROJECTS', [count.toString()])
    },
    ongoingProjectsCount(): number {
      return this.projects.reduce((prev, project) => {
        if (project.status.code !== 'report-ready') {
          prev += 1
        }
        return prev
      }, 0)
    },
  },
  components: { FilterButton },
})
