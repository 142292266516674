import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44f26ad2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Accordion" }
const _hoisted_2 = { class: "ButtonContent" }
const _hoisted_3 = { class: "Inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["Button", { 'IsOpen': _ctx.isOpen }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToggleBody()))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true),
        _createVNode(_component_InlineSVG, {
          class: "Icon",
          src: require('@/assets/svg/angle-down.svg')
        }, null, 8, ["src"])
      ])
    ], 2),
    _createElementVNode("div", {
      ref: "body",
      class: _normalizeClass(["Body", { 'IsOpen': _ctx.isOpen }]),
      style: _normalizeStyle({
        height: _ctx.bodyHeight !== null ? _ctx.bodyHeight + 'px' : '',
      })
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 6)
  ]))
}