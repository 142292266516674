import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-422143e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ProjectDetails SingleProject"
}
const _hoisted_2 = { class: "Title" }
const _hoisted_3 = { class: "CaseNumber" }
const _hoisted_4 = { class: "ProjectDetails EnterpriseProject" }
const _hoisted_5 = { class: "Title" }
const _hoisted_6 = { class: "EnterpriseID" }
const _hoisted_7 = { class: "ProjectCount" }
const _hoisted_8 = {
  key: 0,
  class: "Status"
}
const _hoisted_9 = {
  key: 1,
  class: "Status Ongoing"
}
const _hoisted_10 = {
  key: 2,
  class: "Status Ongoing"
}
const _hoisted_11 = { class: "ProjectDetails CurrentProject" }
const _hoisted_12 = { class: "Title" }
const _hoisted_13 = { class: "CaseNumber" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!

  return (_ctx.isSingleProject)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("address", _hoisted_2, _toDisplayString(_ctx.projectAddress), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.project?.getCaseNumber()), 1),
        _createElementVNode("span", {
          class: _normalizeClass(["Status", _ctx.project?.status.className])
        }, _toDisplayString(_ctx.project?.status.text ?? ''), 3)
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mixWB('ENTERPRISE')), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.project?.data.enterpriseID), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.numberOfProjectsText), 1),
          (_ctx.ongoingProjectsCount === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.mixWB('ALL_PROJECTS_ARE_DONE')), 1))
            : _createCommentVNode("", true),
          (_ctx.ongoingProjectsCount === 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.mixWB('1_PROJECT_IS_ONGOING')), 1))
            : _createCommentVNode("", true),
          (_ctx.ongoingProjectsCount > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.mixWB('X_PROJECTS_ARE_ONGOING', [_ctx.ongoingProjectsCount.toString()])), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SELECTED_PROJECT')), 1),
            _createVNode(_component_FilterButton, { class: "ChangeProject" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.mixWB('SELECT')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("address", null, _toDisplayString(_ctx.projectAddress), 1),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.project?.getCaseNumber()), 1),
          _createElementVNode("span", {
            class: _normalizeClass(["Status", _ctx.project?.status.className])
          }, _toDisplayString(_ctx.project?.status.text ?? ''), 3)
        ])
      ], 64))
}