
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Accordion',
  props: {
    isOpenOnLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bodyHeight: this.isOpenOnLoad ? null : 0,
      isOpen: this.isOpenOnLoad,
    }
  },
  methods: {
    onToggleBody() {
      if (this.isOpen) {
        this.closeBody()
        return
      }

      this.openBody()
    },
    closeBody() {
      if (!this.$refs.body) {
        return
      }

      // Get height
      const { height } = (this.$refs.body as Element).getBoundingClientRect()

      // Set start height
      this.bodyHeight = height

      // Set end height
      requestAnimationFrame(() => {
        this.bodyHeight = 0
      })

      this.isOpen = !this.isOpen
    },
    openBody() {
      if (!this.$refs.body) {
        return
      }

      // Get height
      this.bodyHeight = null

      requestAnimationFrame(() => {
        const { height } = (this.$refs.body as Element).getBoundingClientRect()

        // Set start height
        this.bodyHeight = 0

        requestAnimationFrame(() => {
          // Set end height
          this.bodyHeight = height

          // But do not hardcode the height forever, content inside might change.
          setTimeout(() => {
            this.bodyHeight = null
          }, 300)
        })
      })

      this.isOpen = !this.isOpen
    },
  },
})
