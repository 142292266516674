
import Backdrop from '@/components/Backdrop.vue'
import Filter from '@/components/filter/Filter.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import Menu from '@/components/menu/Menu.vue'
import MenuMobile from '@/components/menu/MenuMobile.vue'
import { appStore, currentStore, filterStore, proProjectsStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Root',
  computed: {
    ...mapState(appStore, ['menu', 'device']),
    ...mapState(currentStore, ['units']),
    ...mapState(proProjectsStore, ['projects']),
    ...mapState(filterStore, {
      filterIsOpen: 'isOpen',
      filterIsDisabled: 'disabled',
    }),
    ...mapState(currentStore, ['units']),
    routerViewKey() {
      const { fullPath, meta } = this.$route

      // If meta.url is null, make sure to destructure component on route change
      return meta.url === null ? fullPath : null
    },
    showMenuBackDrop() {
      const { isHidden, isMinimized, mode } = this.menu

      if (mode !== 'overlay') {
        return false
      }

      if (this.device === 'mobile') {
        return !isHidden
      }

      if (this.device === 'above-mobile') {
        return !isMinimized
      }

      return false
    },
  },
  methods: {
    ...mapActions(appStore, ['setMenuHiddenState', 'setMenuMinimizedState']),
    ...mapActions(filterStore, {
      setFilterIsOpen: 'setIsOpen',
      setFilterIsDisabled: 'setIsDisabled',
    }),
  },
  components: {
    Menu,
    FilterButton,
    Filter,
    Backdrop,
    LanguageSelect,
    MenuMobile,
  },
  created() {
    if (
      this.units.active.buildings.length === 1 &&
      this.projects.length === 1
    ) {
      this.setFilterIsDisabled(true)
    }
  },
})
