import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ffd42b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Root" }
const _hoisted_2 = { class: "Content CustomScrollbar" }
const _hoisted_3 = { Class: "TopBar" }
const _hoisted_4 = { class: "Options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LanguageSelect = _resolveComponent("LanguageSelect")!
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_MenuMobile = _resolveComponent("MenuMobile")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_Backdrop = _resolveComponent("Backdrop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menu),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, { name: "top" }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_LanguageSelect),
          (!_ctx.filterIsDisabled && _ctx.$route.meta.url)
            ? (_openBlock(), _createBlock(_component_FilterButton, { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_router_view, {
        class: "DefaultView",
        key: _ctx.routerViewKey
      })
    ]),
    _createVNode(_component_MenuMobile),
    (!_ctx.filterIsDisabled && _ctx.$route.meta.url)
      ? (_openBlock(), _createBlock(_component_Filter, {
          key: 0,
          class: _normalizeClass({ 'ShowFilter': _ctx.filterIsOpen })
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_ctx.filterIsOpen)
      ? (_openBlock(), _createBlock(_component_Backdrop, {
          key: 1,
          "z-index": 399,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setFilterIsOpen(false)))
        }))
      : _createCommentVNode("", true),
    (_ctx.showMenuBackDrop)
      ? (_openBlock(), _createBlock(_component_Backdrop, {
          key: 2,
          "z-index": 399,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setMenuHiddenState(true), _ctx.setMenuMinimizedState(true)))
        }))
      : _createCommentVNode("", true)
  ]))
}